<template>
  <div>
    <v-btn class="mt-6" color="primary" @click="newUserDialog = true">
      {{ translate("New User") }}
    </v-btn>
    <v-card>
      <v-card-title>
        {{ translate("Users") }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-simple-table fixed-header>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="items"
          :items-per-page="20"
          class="elevation-1"
        >
          <template v-slot:item.role="{ item }">
            <v-select
              dense
              outlined
              v-model="item.role"
              :hide-details="true"
              @input="(role) => userRoleChanged(item, role)"
              style="max-width: 150px"
              :items="[
                'driver',
                'manager',
                'sales_agent',
                'designer',
                'production',
                'chief_designer',
              ]"
            >
              <template slot="selection" slot-scope="data">
                {{ translate(data.item.replace("_", " ")) }}</template
              >
              <template slot="item" slot-scope="data">
                {{ translate(data.item.replace("_", " ")) }}</template
              >
            </v-select>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip :color="item.activated == 1 ? 'success' : 'error'">
              {{
                item.activated == 1 ? translate("Approved") : translate("Not Approved")
              }}</v-chip
            >
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              style="color: var(--v-primary-base)"
              @click.stop="toggleActivation(item)"
            >
              {{ item.activated == 1 ? "mdi-account" : "mdi-account-off" }}
            </v-icon>
            <v-dialog :retain-focus="false" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click.stop="
                    editing_item = JSON.parse(JSON.stringify(item));
                    item.delete_dialog = true;
                  "
                  style="color: var(--v-error-base)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-delete
                </v-icon>
              </template>

              <v-card>
                <v-card-title class="text-h8"> Deleting {{ item.name }}</v-card-title>
                <v-card-text>
                  {{
                    translate(
                      "Are you sure want to delete this account? this process cannot be undone"
                    )
                  }}
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="delete_dialog = false">{{
                    translate("Cancel")
                  }}</v-btn>
                  <v-btn color="primary" @click="handleDelete(item.uid)">{{
                    translate("Delete")
                  }}</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog> </template></v-data-table></v-simple-table
    ></v-card>
    <v-dialog v-model="newUserDialog" :retain-focus="false" width="500">
      <v-card>
        <v-form ref="first_form" lazy-validation class="v-form multi-col-validation">
          <v-card-title class="text-h5 grey lighten-2">
            {{ translate("New User") }}
          </v-card-title>
          <v-card-text>
            <v-col>
              <v-col>
                <v-text-field
                  v-model="name"
                  :rules="[
                    (v) => !!v || 'Item is required!',
                    (v) => v.length >= 4 || 'name is invalid',
                  ]"
                  outlined
                  dense
                  class="shrink"
                  :label="translate('Name')"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="phone_number"
                  :rules="[
                    (v) => !!v || 'Item is required!',
                    (v) => v.length >= 4 || 'Phone Number is invalid',
                  ]"
                  outlined
                  dense
                  class="shrink"
                  :label="translate('Phone Number')"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="email"
                  :rules="[
                    (v) => !!v || 'Item is required!',
                    (v) => v.length >= 5 || 'email is invalid',
                  ]"
                  outlined
                  dense
                  class="shrink"
                  :label="translate('Email')"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="password"
                  :rules="[
                    (v) => !!v || 'Item is required!',
                    (v) => v.length >= 8 || 'password must be at least 8 characters long',
                  ]"
                  outlined
                  dense
                  class="shrink"
                  :label="translate('Password')"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  dense
                  outlined
                  v-model="userRole"
                  :hide-details="true"
                  style="max-width: 150px"
                  :items="[
                    'driver',
                    'manager',
                    'sales_agent',
                    'designer',
                    'production',
                    'chief_designer',
                  ]"
                >
                  <template slot="selection" slot-scope="data">
                    {{ translate(data.item.replace("_", " ")) }}</template
                  >
                  <template slot="item" slot-scope="data">
                    {{ translate(data.item.replace("_", " ")) }}</template
                  >
                </v-select>
              </v-col>
            </v-col>
            <p v-if="errorText != ''" style="color: var(--v-error-base)">
              {{ errorText }}
            </p>
          </v-card-text>
        </v-form>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="insertNewUser()">
            {{ translate("Insert") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "@vue/composition-api";
import { mdiClipboardPlusOutline, mdiDotsVertical } from "@mdi/js";
import {
  changeUserRole,
  deleteUser,
  editSupplier,
  getSuppliers,
  getUsers,
  newUser,
  toggleUserActivation,
} from "@/ApiManager";

export default {
  setup() {
    const edit_supplier_name = ref("");
    const edit_supplier_email = ref("");
    const edit_supplier_phone = ref("");

    return {
      icons: {
        mdiClipboardPlusOutline,
        mdiDotsVertical,
      },
      edit_supplier_name,
      edit_supplier_email,
      edit_supplier_phone,
    };
  },
  data() {
    return {
      newUserDialog: false,
      search: "",
      dialog: false,
      delete_dialog: false,
      name: "",
      phone_number: "",
      email: "",
      password: "",
      userRole: "",
      errorText: "",
      headers: [
        { text: "ID", value: "id" },
        { text: this.translate("Name"), value: "name" },
        { text: this.translate("Role"), value: "role" },
        { text: this.translate("Email"), value: "email" },
        { text: this.translate("Password"), value: "password" },

        { text: this.translate("Phone Number"), value: "phone_number" },
        { text: this.translate("Status"), value: "status" },
        { text: this.translate("Actions"), value: "actions" },
      ],
      items: [],
    };
  },
  created() {
    this.$store.state.loading = true;
    getUsers().then((response) => {
      this.items = response;
      this.$store.state.loading = false;
    });
  },
  methods: {
    async insertNewUser() {
      if (this.$refs["first_form"].validate() == false) {
        return;
      }
      this.$store.state.loading = true;
      var response = await newUser(
        this.name,
        this.phone_number,
        this.email,
        this.password,
        this.userRole
      );
      if (response == "email_exists") {
        this.errorText = "Email already exists.";
        this.$store.state.loading = false;
        return;
      }
      getUsers().then((response) => {
        this.items = response;
        this.$store.state.loading = false;
      });
      this.$store.state.loading = false;

      this.newUserDialog = false;
    },
    async toggleActivation(item) {
      this.$store.state.loading = true;

      await toggleUserActivation(item.id, item.activated == 1 ? 0 : 1);
      getUsers().then((response) => {
        this.items = response;
        this.$store.state.loading = false;
      });
    },
    async userRoleChanged(item, role) {
      this.$store.state.loading = true;

      await changeUserRole(item.id, role);
      this.$store.state.loading = false;
    },
    editClicked(item) {
      this.edit_supplier_name = item.name;
      this.edit_supplier_email = item.email;
      this.edit_supplier_phone = item.phone_number;
    },
    async handleDelete(id) {
      this.delete_dialog = false;
      this.$store.state.loading = true;
      await deleteUser(id);
      getUsers().then((response) => {
        this.items = response;
        this.$store.state.loading = false;
      });
    },
    handleEdit(id) {
      this.$store.state.loading = true;
      this.dialog = false;
      editSupplier(
        id,
        this.edit_supplier_name,
        this.edit_supplier_email,
        this.edit_supplier_phone
      ).then(() => {
        getSuppliers().then((response) => {
          this.items = response;
          this.$store.state.loading = false;
        });
      });
      this.edit_supplier_name = "";
      this.edit_supplier_email = "";
      this.edit_supplier_phone = "";
    },
  },
};
</script>
