var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticClass:"mt-6",attrs:{"color":"primary"},on:{"click":function($event){_vm.newUserDialog = true}}},[_vm._v(" "+_vm._s(_vm.translate("New User"))+" ")]),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.translate("Users"))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-simple-table',{attrs:{"fixed-header":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.items,"items-per-page":20},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticStyle:{"max-width":"150px"},attrs:{"dense":"","outlined":"","hide-details":true,"items":[
              'driver',
              'manager',
              'sales_agent',
              'designer',
              'production',
              'chief_designer' ]},on:{"input":function (role) { return _vm.userRoleChanged(item, role); }},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.translate(data.item.replace("_", " "))))]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.translate(data.item.replace("_", " "))))]}}],null,true),model:{value:(item.role),callback:function ($$v) {_vm.$set(item, "role", $$v)},expression:"item.role"}})]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.activated == 1 ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.activated == 1 ? _vm.translate("Approved") : _vm.translate("Not Approved")))])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticStyle:{"color":"var(--v-primary-base)"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleActivation(item)}}},[_vm._v(" "+_vm._s(item.activated == 1 ? "mdi-account" : "mdi-account-off")+" ")]),_c('v-dialog',{attrs:{"retain-focus":false,"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"color":"var(--v-error-base)"},on:{"click":function($event){$event.stopPropagation();_vm.editing_item = JSON.parse(JSON.stringify(item));
                  item.delete_dialog = true;}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"text-h8"},[_vm._v(" Deleting "+_vm._s(item.name))]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.translate( "Are you sure want to delete this account? this process cannot be undone" ))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.delete_dialog = false}}},[_vm._v(_vm._s(_vm.translate("Cancel")))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleDelete(item.uid)}}},[_vm._v(_vm._s(_vm.translate("Delete")))]),_c('v-spacer')],1)],1)],1)]}}])})],1)],1),_c('v-dialog',{attrs:{"retain-focus":false,"width":"500"},model:{value:(_vm.newUserDialog),callback:function ($$v) {_vm.newUserDialog=$$v},expression:"newUserDialog"}},[_c('v-card',[_c('v-form',{ref:"first_form",staticClass:"v-form multi-col-validation",attrs:{"lazy-validation":""}},[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.translate("New User"))+" ")]),_c('v-card-text',[_c('v-col',[_c('v-col',[_c('v-text-field',{staticClass:"shrink",attrs:{"rules":[
                  function (v) { return !!v || 'Item is required!'; },
                  function (v) { return v.length >= 4 || 'name is invalid'; } ],"outlined":"","dense":"","label":_vm.translate('Name'),"hide-details":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',[_c('v-text-field',{staticClass:"shrink",attrs:{"rules":[
                  function (v) { return !!v || 'Item is required!'; },
                  function (v) { return v.length >= 4 || 'Phone Number is invalid'; } ],"outlined":"","dense":"","label":_vm.translate('Phone Number'),"hide-details":""},model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=$$v},expression:"phone_number"}})],1),_c('v-col',[_c('v-text-field',{staticClass:"shrink",attrs:{"rules":[
                  function (v) { return !!v || 'Item is required!'; },
                  function (v) { return v.length >= 5 || 'email is invalid'; } ],"outlined":"","dense":"","label":_vm.translate('Email'),"hide-details":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',[_c('v-text-field',{staticClass:"shrink",attrs:{"rules":[
                  function (v) { return !!v || 'Item is required!'; },
                  function (v) { return v.length >= 8 || 'password must be at least 8 characters long'; } ],"outlined":"","dense":"","label":_vm.translate('Password'),"hide-details":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-col',[_c('v-select',{staticStyle:{"max-width":"150px"},attrs:{"dense":"","outlined":"","hide-details":true,"items":[
                  'driver',
                  'manager',
                  'sales_agent',
                  'designer',
                  'production',
                  'chief_designer' ]},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.translate(data.item.replace("_", " "))))]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.translate(data.item.replace("_", " "))))]}}]),model:{value:(_vm.userRole),callback:function ($$v) {_vm.userRole=$$v},expression:"userRole"}})],1)],1),(_vm.errorText != '')?_c('p',{staticStyle:{"color":"var(--v-error-base)"}},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.insertNewUser()}}},[_vm._v(" "+_vm._s(_vm.translate("Insert"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }